<template>
    <span>
        <div class="">
            <h4 class="ol-dashboard__container-title">Wishlist</h4>
            <ProductWishlist  :products="data" @triggerClick="pilihHapus" />
            <ProductWishlistLoading :qty="per_page" v-if="loading"/>
            <center v-if="morePage"><br><button @click="more()" type="button" class="ol-btn" style="">Produk Lainnya</button></center>
            <center v-if="wishlistkosong">-Tidak ada produk apapun di daftar wishlist-</center>
        </div>
	    <LoadingImg v-if="loadingHapus" />
    
    </span>
</template>

<script>
import ProductWishlist from '@/components/product/ProductWishlist.vue'
import ProductWishlistLoading from '@/components/loading/ProductWishlistLoading.vue'
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import Password from 'vue-password-strength-meter'
import globalUrl from '@/util/globallUrl'
import {mapActions,mapGetters} from "vuex";
const URL = globalUrl.WS_PROFILE.PASSWORD;

export default {
    name:"Wishlist",
    components:{
        ProductWishlistLoading,
        Password,
        ProductWishlist,
        ProductList,
        ProductLoader,
    },
    computed:{
        ...mapGetters('auth', ['user']),
		...mapGetters("product/detail", ["recommended"]),
    },
    data(){
        return {
            loading:true,
            wishlistkosong:false,
            morePage:false,
            loadingHapus:false,
			loadingRecommended:true,
            page:1,
            per_page:12,
            data:[]
        }
    },
    methods:{
        ...mapActions("profile/wishlist", ["syncWishlistGuest"]),
        async getData() {
            this.loading=true;
            let data={
                page : this.page,
                per_page:this.per_page
            };
            await globalUrl.WS_TRANSACTION.WISHLIST.GET(data).then(response => {
                let _data = response.data.data;
                // console.log(_data);
                this.morePage = _data.pagination.more;
                this.data.push(..._data.results);
                this.$store.commit('profile/wishlist/setData', this.data);
                this.loading=false;
                if(this.data.length<1){
                    this.wishlistkosong=true
                }
            }).catch(error => {
                this.loading=false;
            });
        },
        more() {
            this.morePage=false;
            this.page++;
            this.getData();
        },
        async delete(product) {
            this.loadingHapus =true;
            await globalUrl.WS_TRANSACTION.WISHLIST.DELETE(product.id).then(response => {
                let _data = response.data.data;
                // console.log(_data);
                this.data.splice(this.data.indexOf(product), 1);
                this.loadingHapus=false;
            }).catch(error => {
                this.loadingHapus=false;
            });

        },
        pilihHapus:function(id){
            this.delete(id);
        }
    },
    mounted() {
        this.syncWishlistGuest();
        this.getData();
    }
}
</script>

<style lang="css" scoped>
    @import '../../assets/css/bootstrap.css';
    @import "../../assets/css/wishlist.css"; 
</style>
