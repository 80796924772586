<template>
    <div >
        <div class="row ol-wishlist-list" style=" margin: auto;">
            <div class="ol-wishlist-product-container" v-for="i in qty" :key="i">
                <div class="row ol-wishlist-list" style="margin: auto;">
                    <div class="ol-wishlist-product">
                        <!-- <a class="ol-wishtlist-close" style="float: right;" href="#" title="Hapus">
                            <i class="ol-fa fas fa-times"></i>
                        </a> -->
                        <!-- <div class="ol-wishlist-img">
                            <img srcset="">
                        </div> -->
                        <div class="ol-wishlist-img" style="height:244.78px;margin:5px 0px 5px 0px">
                            <SkeletonLoader/>
                        </div>
                        <!-- <div class="ol-wishlist-watermark">
                            <img srcset="images/brand/og-olympic.png">
                        </div> -->
                        <div class="row">

                            <div class="col ol-wishlist-contain-desc">
                                <h2  style="height:21px;"><SkeletonLoader/></h2>
                                <div class="row">
                                    <div class="col ol-wishlist-desc" style="padding-left: 0px;padding-right: 0px;">
                                        
                                        <div class="ol-wishlist-addcart row" style="height:41px;">
                                            <SkeletonLoader/>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"ProductWishlistLoading",
    props:['qty'],
    components:{
        SkeletonLoader
    },
}
</script>
<style>
  @import "../../assets/css/wishlist.css"; 
</style>
