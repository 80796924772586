<template>
    <div >

        <div class="row ol-wishlist-list" style=" margin: auto;">
            <div class="ol-wishlist-product-container" v-for="product in products" :key="product.id">
                <div class="ol-wishlist-product">
                    <!-- <a class="ol-wishtlist-close" style="float: right;" href="#" title="Hapus">
                        <i class="ol-fa fas fa-times"></i>
                    </a> -->
                    <!-- <div class="ol-wishlist-img">
                        <img srcset="">
                    </div> -->
                    <div class="ol-wishlist-img" v-if="product.r_uni_products.r_uni_product_images">
                        <router-link :to="product.r_uni_products.bundling == 1 ? '/bundle/'+product.r_uni_products.slug:'/product/'+product.r_uni_products.slug" v-for="(image,i) in product.r_uni_products.r_uni_product_images.slice(0, 3)" :key="image.id" :class="'ol-home-product__content-item-list--item-figure-link ol-home-product__content-item-list--item-figure-link--'+(i+1)">
                            <img :src="image.image_url" :alt="image.alt_text" :title="product.r_uni_products.name">
                        </router-link>

                        <router-link :to="product.r_uni_products.bundling == 1 ? '/bundle/'+product.r_uni_products.slug:'/product/'+product.r_uni_products.slug" v-if="!product.r_uni_products.r_uni_product_images || product.r_uni_products.r_uni_product_images.length < 1"  class="ol-home-product__content-item-list--item-figure-link ol-home-product__content-item-list--item-figure-link--">
                            <img :src="noImage()">
                        </router-link>
                    </div>
                    <!-- <div class="ol-wishlist-watermark">
                        <img srcset="images/brand/og-olympic.png">
                    </div> -->
                    <div class="row">

                        <div class="col ol-wishlist-contain-desc">
                            <h2>{{product.r_uni_products.name.length > 60 ?product.r_uni_products.name.substring(0, 60)+"..." : product.r_uni_products.name}}</h2>
                            <div class="row">
                                <div class="col ol-wishlist-desc" style="padding-left: 0px;padding-right: 0px;">
                                    <!-- <div class="ol-wishlist-rating">
                                        <div class="ol-wishlist-star-wrap">
                                            <i class="fa fa-star"></i>
                                        </div>

                                        <strong>{{ product.r_uni_products.rating }}</strong>

                                    </div> -->
                                    <span :v-if="product.r_uni_products.discount_max && product.r_uni_products.discount_max != '0%'" class="ol-home-product__content-item-list--item-price-real">{{product.r_uni_products.price_normal}}</span><br/>
                                    <small class="ol-home-product__content-item-list--item-price-discount">{{product.r_uni_products.price_range}}
                                    </small>
                                    <!-- <p>Tipe: <strong>3pcs</strong></p>
                                    <p>Warna: <strong>Mix</strong></p> -->
                                    <!-- <div>
                                        <img srcset="images/brand/olymplast.png">
                                    </div> -->
                                    <div class="ol-wishlist-addcart row" >
                                        <a class="ol-wishlist-delete col"  @click="$emit('triggerClick',product)" style="color:grey;">
                                            <i class="fas fa-trash ol-fa"></i>
                                        </a>
                                        <router-link :to="product.r_uni_products.bundling == 1 ? '/bundle/'+product.r_uni_products.slug:'/product/'+product.r_uni_products.slug" class="ol-btn col-9">
                                            Lihat Produk
                                        </router-link>
                                        
                                    </div>
                                </div>


                            </div>

                        </div>


                    </div>
                    <span v-if="product.r_uni_products.discount_max && product.r_uni_products.discount_max != '0%'" class="ol-home-product__content-item-list--item-discount">{{product.r_uni_products.discount_max}}<br>OFF</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"ProductWishlist",
    props:["products"],
}
</script>
